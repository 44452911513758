var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conversation-bubble",class:{
    'conversation-bubble--current': _vm.isCurrentUser,
    'conversation-bubble--system': _vm.isSystem,
    'conversation-bubble--create': _vm.isCreateConversation
  }},[_c('div',{staticClass:"conversation-bubble__top",class:{ 'conversation-bubble__top--system green': _vm.isSystem }},[_c('p',{staticClass:"small mb-1",domProps:{"innerHTML":_vm._s(_vm.bodyWithLink)}})]),_c('div',{staticClass:"conversation-bubble__bottom",class:{ 'conversation-bubble__bottom--system green-lighter': _vm.isSystem }},[(_vm.hasActions)?_c('p',{staticClass:"text-light font-weight-bold"},[(_vm.isRatingRequest && _vm.contractId)?_c('span',{staticClass:"conversation-bubble__link"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"star-half","aria-hidden":"true"}}),_c('router-link',{attrs:{"to":{
            name: _vm.$consts.urls.URL_CONTRACT_RATING,
            params: { id: this.contractId }
          }}},[_vm._v(" "+_vm._s(_vm.$t("conversation.btn-rate-transaction"))+" ")])],1):_vm._e(),(_vm.isContractCreatedOrUpdated && _vm.contractId)?_c('span',{staticClass:"conversation-bubble__link"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"file-earmark-check","aria-hidden":"true"}}),_c('router-link',{attrs:{"to":{ name: _vm.$consts.urls.URL_CONTRACT_DETAIL, params: { id: _vm.contractId } }}},[_vm._v(" "+_vm._s(_vm.$t("btn.contract-detail")))])],1):_vm._e(),(_vm.isCreateConversation)?_c('span',{staticClass:"conversation-bubble__link text-white h6 font-family-base"},[_c('b-icon-reply-fill',{staticClass:"mr-2 mt-1 lead",attrs:{"aria-hidden":"true"}}),_c('b-button',{staticClass:"conversation-bubble__link-btn p-0 text-left text-white font-weight-bold text-decoration-underline",attrs:{"variant":"link"},on:{"click":_vm.onCreateConversation}},[_vm._v(" "+_vm._s(_vm.$t("btn.create-conversation-auto-message"))+" ")])],1):_vm._e()]):_vm._e(),_c('p',{staticClass:"small text-right m-0",class:{ 'text-muted': _vm.isCurrentUser }},[_c('img',{staticClass:"conversation-bubble__icon mt-n1 mr-1",attrs:{"src":_vm.messageIcon(_vm.isSystem),"alt":_vm.$t('alt.message-sent-at')}}),_vm._v(" "+_vm._s(_vm.messageDate)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }