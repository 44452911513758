
        let gqlDocs = [{"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserProfileById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userProfile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"firstName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"organizationName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"publicName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showPhoneNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showEmail"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"publicPhoneNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"publicEmail"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"averageRating"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"registrationDate"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":338}}];
        let attrs = {"noAnonymousOperations":false,"vue":true,"type":"custom","index":"0","blockType":"graphql"} || {};
        let handler = require("D:\\a\\1\\s\\YellowDuck.FE\\node_modules\\vue-graphql-loader\\lib\\handler");

        if (handler.default) {
          handler = handler.default;
        }
        module.exports = function vueGraphqlLoader(component) {
          handler(component, gqlDocs, attrs);
        }